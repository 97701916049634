import moment from 'moment';
import { LocationLaunchListResponse, Task } from './dashboard-grapghql';
import { processLocationTasks } from '../tasks/utils';
import { useComplianceApprovalStats } from 'sub-components/nexus/Compliance/Create/components';

export const getStatus = (
  isLive: string,
  tasks: any[],
  startDate: string,
  phases: any[],
  locationName: string
): {
  status: string | undefined;
  summary: { phaseName: string | undefined; overdueDays: number | undefined };
} => {
  if (isLive) {
    return {
      status: undefined,
      summary: { phaseName: undefined, overdueDays: undefined },
    };
  }

  // Group tasks by their phaseEid
  const phaseGroups = {};
  tasks?.forEach((task) => {
    if (!phaseGroups?.[task?.phaseEid]) {
      phaseGroups[task.phaseEid] = { tasks: [] };
    }
    phaseGroups?.[task?.phaseEid]?.tasks?.push(task);
  });

  let phasesWithIncompleteTasks = 0;
  let lastPhaseId = '';

  // Iterate through each phase group
  Object.entries(phaseGroups)?.forEach(([phaseEid, group]) => {
    const hasIncomplete = group?.tasks?.some((task) => !task?.isCompleted);

    // Count the number of phases with incomplete tasks
    if (hasIncomplete) {
      phasesWithIncompleteTasks++;

      // If this is the first phase with incomplete tasks, set lastPhaseId
      if (phasesWithIncompleteTasks === 1) {
        lastPhaseId = phaseEid;
      }
    }
  });

  const allTasksCompleted = tasks?.every((task) => task?.isCompleted);

  const overdueTasks = tasks
    ?.filter(
      (task: any) =>
        !task?.isCompleted &&
        task?.dueDate &&
        moment(task?.dueDate).isBefore(moment.utc())
    )
    .sort((a: any, b: any) => {
      return moment(a?.dueDate).diff(moment(b?.dueDate));
    });

  const firstOverdueTask = overdueTasks?.[0] || null;

  const isLagging = overdueTasks?.length >= 1;

  const lastPhaseTasks = phaseGroups?.[lastPhaseId]?.tasks || [];
  const lastPhaseLagging = lastPhaseTasks?.some(
    (task: any) =>
      !task?.isCompleted &&
      task?.dueDate &&
      moment(task?.dueDate).isBefore(moment.utc())
  );

  let status: string | undefined;

  if (lastPhaseLagging) {
    status = 'Lagging';
  } else if (phasesWithIncompleteTasks === 1) {
    status = 'Last Phase';
  } else if (allTasksCompleted) {
    status = 'Ready to Go Live';
  } else if (isLagging) {
    status = 'Lagging';
  }

  return {
    status,
    summary: {
      phaseName: firstOverdueTask
        ? firstOverdueTask?.phase
        : lastPhaseTasks?.[0]?.phase,
      overdueDays: firstOverdueTask
        ? moment.utc().diff(moment(firstOverdueTask.dueDate), 'days')
        : undefined,
    },
  };
};

// Helper function to calculate lagging and on-time locations
export const calculateLocationStatus = (
  locations: Array<{
    isLive: boolean;
    tasks: Array<{
      dueDate: string;
      isCompleted: boolean;
      phaseEid: string;
      phase: string;
    }>;
  }>
) => {
  // Initialize counters for lagging and onTime tasks
  const status = { lagging: 0, onTime: 0 };

  // Filter out live locations
  const nonLiveLocations = locations?.filter((location) => !location?.isLive);

  // Iterate through non-live locations
  nonLiveLocations?.forEach((location) => {
    const locationStatus = getStatus(
      location?.isLive,
      location?.tasks?.filter((task: any) => task?.isPhaseSelected !== false),
      '', // startDate (not needed for lagging check)
      [], // phases (not needed for lagging check)
      '' // locationName (not needed for lagging check)
    );

    // If status is 'Lagging', increment lagging counter, otherwise increment onTime
    if (locationStatus?.status === 'Lagging') {
      status.lagging += 1;
    } else {
      status.onTime += 1;
    }
  });

  return status;
};

//to calculate average completion time
export const calculateBusinessCompletionRate = (
  locations: LocationLaunchListResponse['LocationLaunchList']
) => {
  let totalTasks = 0;
  let completedTasks = 0;

  // Filter out "live" locations
  const nonLiveLocations = locations?.filter((location) => !location?.isLive);

  nonLiveLocations?.forEach((location) => {
    totalTasks += location?.tasks?.length;
    completedTasks += location?.tasks?.filter(
      (task) => task?.isCompleted
    )?.length;
  });

  const completionRate =
    totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

  return parseFloat(completionRate?.toFixed(2)); // Rounded to 2 decimal places
};

//completion rate for a single location
export const calculateLocationCompletionRate = (
  location: LocationLaunchListResponse['LocationLaunchList'][0]
) => {
  const totalTasks = location?.tasks?.length;
  const completedTasks = location?.tasks?.filter(
    (task) => task?.isCompleted
  )?.length;
  const completionRate =
    totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

  return parseFloat(completionRate?.toFixed(2)); // Rounded to 2 decimal places
};

//Date format
export const formatDateToCustom = (isoDate: string): string => {
  if (!isoDate) return '-';
  const date = new Date(isoDate);

  const day = date?.getDate();
  const month = date?.toLocaleString('en-US', { month: 'short' }); // Short month name
  const year = date?.getFullYear();

  // Add suffix for the day (e.g., 1st, 2nd, 3rd, etc.)
  const getDayWithSuffix = (day: number): string => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };

  return `${getDayWithSuffix(day)} ${month} ${year}`;
};

//Current Task

//Cards data
export const transformLocationLaunchList = (launchList: any) => {
  return launchList.map((launch: any) => {
    const { status, summary } = getStatus(
      launch?.isLive,
      launch?.tasks?.filter((task: any) => task?.isPhaseSelected !== false),
      launch?.startDate,
      launch?.phases,
      launch?.location?.name
    );

    return {
      locationId: launch?.locationId,
      locationName: launch?.location?.name,
      locationCity: launch?.locationDetails?.address?.city,
      locationState: launch?.locationDetails?.address?.state,
      isLive: launch?.isLive,
      liveDate: launch?.liveDate,
      startDate: launch?.startDate,
      phases: launch?.phases,
      phasesLength: launch?.phases?.length,
      launchId: launch?.launchId,
      status,
      phaseName: summary?.phaseName,
      overdueDays: summary?.overdueDays,
      board: launch?.board || '-',
      tasks: launch?.tasks
        .filter((task: any) => task?.isPhaseSelected !== false)
        .map((task: any) => ({
          title: task?.title,
          phase: task?.phase,
          dueDate: task?.dueDate,
          isCompleted: task?.isCompleted,
          phaseEid: task?.phaseEid,
          completedAt: task?.completedAt,
        })),
    };
  });
};

//Table Data
export const transformTabledata = (data: any) => {
  // Filter out locations that are not live and have all tasks completed
  // Filter out locations that are not live and have incomplete tasks
  const filteredData = data?.filter((location: any) => {
    // Ensure location is not live
    if (location?.isLive) {
      return;
    }

    // Check if all tasks are completed (e.g., task.isCompleted === true)
    // Check if there are any incomplete tasks with `phaseRequired: true`
    const hasIncompleteTasks = location?.tasks?.some(
      (task: any) => !task?.isCompleted && task?.isPhaseSelected !== false
    );
    // Include the location if it has incomplete tasks
    return hasIncompleteTasks;
  });

  return filteredData?.map((location: any) => {
    // Find the Lagging state
    const currentTask = location?.tasks
      .filter((task) => {
        return !task.isCompleted;
      })
      .sort((a, b) =>
        moment(a.startDate).isBefore(moment(b.startDate)) ? -1 : 1
      )
      .find((task) => {
        // Check if the task has partially completed steps
        const hasPartiallyCompletedSteps = task.steps.some(
          (step: any) => !step.isCompleted
        );
        // Task is current if it has partially completed steps or no steps
        return (
          hasPartiallyCompletedSteps &&
          task.status !== 'locked' &&
          moment(task.startDate).isSameOrBefore(moment().utc())
        );
      });

    const { status } = getStatus(
      location?.isLive,
      location?.tasks?.filter((task: any) => task?.isPhaseSelected !== false),
      location?.startDate,
      location?.phases,
      location?.location?.name
    );
    const completionRate = calculateLocationCompletionRate(location);
    return {
      name: location?.location?.name || 'Unknown',
      locationId: location?.locationId || '-',
      launchId: location?.launchId || '-',
      status: status === 'Lagging' ? 'Lagging' : 'On-Track',
      rate: completionRate || 0,
      task: currentTask?.title || 'No Task',
      startDate: location?.startDate || '-',
      goLiveDate: location?.liveDate || '-',
      phase: currentTask?.phase || '-',
      taskType: currentTask?.title || '-',
      taskCategoryEid: currentTask?.taskCategoryEid || '-',
      board: location?.board || '-',
    };
  });
};
